$leave-taller-input-height: 40px;

.chr_leave__page-selector-dropdown {
  margin-bottom: $crm-panel-nested-indent;
  margin-right: $crm-gap-small;
  position: relative;
  top: 1px;
  width: 100%;

  select {
    height: $leave-taller-input-height;
  }

  .crm_custom-select__arrow {
    line-height: $leave-taller-input-height;
    width: $leave-taller-input-height;
  }
}
