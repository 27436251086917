$chr-leave-calendar-cell-height:                           35px;
$chr-leave-calendar-cell-font-size:                        10px;
$chr-leave-calendar-item-generic-leave-background-color:   #4d4d68;
$chr-leave-calendar-item-weekend-background-color:         #c7cbd0;
$chr-leave-calendar-item-non-working-day-background-color: #41afcb;
$chr-leave-calendar-item-public-holiday-background-color:  #8ec68a;
$chr-leave-calendar-item-requested-border-height:          0.5em;
$chr-leave-calendar-legend-header-top-margin:              20px;
$chr-leave-calendar-legend-badge-border-radius:            2px;
$chr-leave-calendar-legend-badge-padding:                  8px;
$chr-leave-calendar-legend-badge-margin:                   10px;
$chr-leave-calendar-legend-badge-size:                     24px;
$chr-leave-calendar-legend-badge-square-right-margin:      20px;
$chr-leave-calendar-legend-badge-square-font-size:         12px;
$chr-leave-calendar-legend-badge-square-hours-font-size:   6px;

.chr_leave-calendar__admin-filters-hint {
  display: inline-block;
  font-size: 20px;
  vertical-align: middle;
}

.chr_leave-calendar__admin-filters-select {
  display: inline-block;
  margin-right: 20px;
  width: calc(100% - 50px);
}

.chr_leave-calendar__dates-container {
  overflow-x: auto;

  .chr_leave-calendar {
    @media (min-width: $screen-lg-min) {
      table-layout: fixed;
    }
  }
}

.chr_leave-calendar__day {
  font-size: $chr-leave-calendar-cell-font-size !important;
  line-height: 1.4em !important;
  padding: 10px !important;
  text-align: center !important;

  @media (min-width: $screen-lg-min) {
    padding: 10px 0 !important;
  }
}

.chr_leave-calendar__day--disabled {
  opacity: 0.5;
}

.chr_leave-calendar__day--current {
  color: $brand-primary !important;
}

.chr_leave-calendar__day-container--type--non-working {
  background-color: $chr-leave-calendar-item-non-working-day-background-color;
}

.chr_leave-calendar__day-container--type--public-holiday {
  background-color: $chr-leave-calendar-item-public-holiday-background-color;
}

.chr_leave-calendar__day-container--type--weekend {
  background-color: $chr-leave-calendar-item-weekend-background-color;
}

.chr_leave-calendar__day-container {
  height: 100%;
}

.chr_leave-calendar__item {
  color: #fff;
  display: block;
  font-size: $chr-leave-calendar-cell-font-size;
  height: 100%;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  width: 100%;

  > span {
    @include absolute-centered();
    height: 1.2em;
  }

  > .chr_leave-calendar__time-unit {
    font-size: 7px;
    height: 1em;
    margin-left: -1px;
  }

  > a {
    @include absolute-centered();
    cursor: pointer;
  }
}

.chr_leave-calendar__item--half {
  height: 50%;

  &:not(:first-child) {
    height: calc(50% - 1px);
    margin-top: 1px;
  }
}

.chr_leave-calendar__item--more {
  border: 2px solid #d3dee2;

  .chr_leave-calendar__item__label {
    color: #4d4d69;
  }
}

.chr_leave-calendar__item--disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

// Public Holiday, Weekend and Non Working day needs to be specified before other styles
// to give other styles more priority
.chr_leave-calendar__item--type--late {
  background-color: #eca67e;
  border-color: #eca67e;
}

.chr_leave-calendar__item--alt {
  background-color: #fff !important;
  border: 2px solid transparent;
  color: $gray-darker;
}

.chr_leave-calendar__item--requested {
  @include striped-background(rgba(255, 255, 255, 0.3));

  &.chr_leave-calendar__item--alt {
    @include striped-background($crm-white);
    background-color: $crm-grayblue-dark !important;
  }
}

.chr_leave-calendar__items {
  > td {
    height: $chr-leave-calendar-cell-height;
    overflow: hidden;
  }

  .chr_leave-calendar__items_day-cell {
    padding: 0 !important;
  }

  leave-calendar-day {
    display: block;
    height: 100%;
  }
}

.chr_leave-calendar__legend__title {
  font-size: $font-size-small;
}

.chr_leave-calendar__legend__header:not(:first-child) {
  margin-top: $chr-leave-calendar-legend-header-top-margin;
}

.chr_leave-calendar__legend__type-badge,
.chr_leave-calendar__legend__other-badge-wrapper {
  float: left;
  line-height: $chr-leave-calendar-legend-badge-size;
  margin-top: $chr-leave-calendar-legend-badge-margin;
  white-space: nowrap;
}

.chr_leave-calendar__legend__other-badge-wrapper {
  margin-right: $chr-leave-calendar-legend-badge-square-right-margin;
}

.chr_leave-calendar__legend__type-badge,
.chr_leave-calendar__legend__other-badge {
  color: $gray-dark;
  height: $chr-leave-calendar-legend-badge-size;
}

.chr_leave-calendar__legend__type-badge {
  border-radius: $chr-leave-calendar-legend-badge-border-radius;
  color: $crm-white;
  margin-right: $chr-leave-calendar-legend-badge-margin;
  margin-top: 10px;
  padding: 0 $chr-leave-calendar-legend-badge-padding;

  &.chr_leave-calendar__legend__type-badge-weekend {
    background-color: $chr-leave-calendar-item-weekend-background-color;
  }

  &.chr_leave-calendar__legend__type-badge-non-working-day {
    background-color: $chr-leave-calendar-item-non-working-day-background-color;
  }

  &.chr_leave-calendar__legend__type-badge-public-holiday {
    background-color: $chr-leave-calendar-item-public-holiday-background-color;
  }

  &.chr_leave-calendar__legend__type-badge-generic-leave {
    background-color: $chr-leave-calendar-item-generic-leave-background-color;
  }

  &.chr_leave-calendar__legend__type-badge-selected,
  &.chr_leave-calendar__legend__type-badge-clickable:hover {
    border: solid 1px $crm-white;
    border-radius: #{$chr-leave-calendar-legend-badge-border-radius + 2px};
    box-shadow: 0 0 1px 0 $chr-leave-calendar-item-weekend-background-color;
    height: #{$chr-leave-calendar-legend-badge-size + 2px};
    margin-bottom: -1px;
    margin-left: -2px;
    margin-right: #{$chr-leave-calendar-legend-badge-margin - 1px};
    margin-top: #{$chr-leave-calendar-legend-badge-margin - 1px};
    padding-left: #{$chr-leave-calendar-legend-badge-padding + 1px};
  }

  &.chr_leave-calendar__legend__type-badge-not-selected {
    opacity: 0.4;
  }
}

.chr_leave-calendar__legend__reset-absence-types-filter {
  color: $gray-darker;
}

.chr_leave-calendar__legend__other-badge {
  border: solid 1px $crm-grayblue-dark;
  border-radius: $chr-leave-calendar-legend-badge-border-radius;
  float: left;
  font-size: $chr-leave-calendar-legend-badge-square-font-size;
  font-weight: 600;
  margin-right: $chr-leave-calendar-legend-badge-margin;
  text-align: center;
  width: $chr-leave-calendar-legend-badge-size;

  &.chr_leave-calendar__legend__other-badge-hours {
    font-size: $chr-leave-calendar-legend-badge-square-hours-font-size;
    font-weight: $badge-font-weight;
  }

  &.chr_leave-calendar__legend__other-badge-requested {
    @include striped-background(rgba(255, 255, 255, 0.6));
    background-color: $crm-grayblue-dark !important;
    border: 0;
  }
}

.chr_leave-calendar__month-container {
  margin: 0;

  > div {
    padding: 0;
  }
}

.chr_leave-calendar__month-header {
  background: $panel-default-heading-bg;

  .panel-title {
    font-size: 16px;
  }
}

.chr_leave-calendar__month-header__go-to-current-month {
  text-transform: uppercase;
}

.chr_leave-calendar__month-header__go-to-current-month,
.chr_leave-calendar__month-header__month-paginator {
  margin-right: 10px;
}

.chr_leave-calendar__month-header__month-paginator {
  color: $gray-darker;
  padding-left: 0;
  padding-right: 0;
}

.chr_leave-calendar__name {
  font-size: $font-size-small !important;
  line-height: 100%;
  padding: 0 20px !important;
  text-align: left;
  text-overflow: ellipsis;
  width: 100%;
}

.chr_leave-calendar__name_header,
.chr_leave-calendar__day {
  height: 49px;
}

.chr_leave-calendar__name_header {
  padding-bottom: 10px !important;
}

.chr_leave-calendar__name_cell {
  vertical-align: middle !important;
}

.chr_leave-calendar__names-container {
  .chr_leave-calendar {
    table-layout: fixed;
  }
}

.chr_leave-calendar__pagination {
  font-size: $chr-leave-calendar-cell-font-size;
  text-align: center;

  [class*='btn'] {
    line-height: 1em;
    outline: none;
    padding: 0;

    .fa {
      color: $gray;
    }

    &[disabled] {
      .fa {
        opacity: 0.5;
      }
    }

    &:hover:not([disabled]) {
      .fa {
        color: $gray-darker;
      }
    }
  }
}

.chr_leave-calendar__pagination-next {
  border-top: 0;

  th {
    height: 24px;
    padding: 4px 0 6px !important;
  }

  .chr_leave-calendar__pagination {
    padding-right: 3px !important;
  }
}
