.chr_manager_calendar__header {
  @include uniform-padding(20px);

  padding-top: 15px;
  margin: 0;
  background-color: $chr-panel-header-background;
  border-bottom: 1px solid $chr-panel-border;

  .checkbox {
    margin: 0;
  }

  @media screen and (max-width: $screen-md-max) {
    > div:not(:last-child) {
      padding-bottom: 20px;
    }
  }
}

.chr_manager_calendar__sub-header {
  @include uniform-padding(20px);

  background-color: $chr-panel-header-background;
  margin: 0;

  @media screen and (max-width: $screen-md-max) {
    > div:not(:last-child) {
      padding-bottom: 20px;
    }

    .ui-select-container {
      height: auto;
    }
  }
}
