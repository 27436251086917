/**
 * Shared style between the default modal and the custom CiviHR modal
 */
body.modal-open {
  overflow: hidden;

  > .modal-default,
  > [class*="modal-civihr-custom"] {

    bottom: 0 !important;
    left: 0 !important;
    overflow-x: hidden !important;
    overflow-y: auto !important;
    position: fixed !important;
    right: 0 !important;
    top: 0 !important;

    .loading-spinner {
      height: 300px;
      left: 0 !important;
      line-height: 280px;
      position: relative !important;
      text-align: center;
      top: 0 !important;
    }

    .modal-body {
      @include clearfix;
      width: 100% !important;
    }

    .modal-content {
      min-height: inherit;
      position: relative;
    }

    .modal-dialog {
      height: auto !important;
      margin: 40px auto !important;
    }

    @media (max-width: $screen-sm-min) {
      .modal-dialog {
        margin: auto !important;
      }
    }
  }
}



/**
 * Custom CiviHR modal
 */

@include custom-modal();
@include custom-modal('--large', 755); // [modifier] Larger custom CiviHR modal

.modal-civihr-custom__footer {
  @include clearfix;

  padding-top: #{$crm-custom-modal-body-padding}px;
  text-align: right;
}

.modal-civihr-custom__section {
  @include clearfix;

  border-bottom: 1px solid $gray-light;
  padding: #{$crm-custom-modal-body-padding * 2}px #{$crm-custom-modal-body-padding}px;

  &:last-of-type {
    border-bottom: 0;
    padding-bottom: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }
}

.modal-civihr-custom__section--no-padding {
 @extend .modal-civihr-custom__section;

 border: 0;
 margin: 0 #{-$crm-custom-modal-body-padding}px;
 padding: 0;
}

.modal-civihr-custom__section--strip {
  @extend .modal-civihr-custom__section;

  background: $crm-gray-light-alt;
  margin: 0 #{-$crm-custom-modal-body-padding}px;
  padding: #{$crm-custom-modal-body-padding * 2}px;
}



/**
 * HR Resource modal
 */
.view-display-id-hr_resources_modal {
  margin: 0;
  padding: 0;

  .hr-documents-body-block + .views-field-nothing {
    border-top: 1px solid $gray-light;
    margin-top: 20px;
    padding-top: 10px;
  }

  .resource-uploaded-date {
    color: $light-pale;
  }

  .views-label-body {
    color: $gray-darker;
    margin-bottom: 20px;
    display: inline-block;
  }

  p:first-child {
    margin-top: 0;
  }
}
