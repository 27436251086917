// Styles for blocks.
// --------------------------------------------------

#block-welcome-logo {
  padding: {
    bottom: 0px;
    top: 20px;
  }
  margin-top: 30px;

  .pane-content {
    @include box-shadow($crm_box-shadow-global);
    background: $light;
    max-width: 100%;
    width: 125px;
    height: 125px;
    border-radius: 125px;
    margin: 0 auto 17px;
    text-align: center;
    position: relative;

    img {
      max-width: 80%;
      left: 50%;
      position: absolute;
      top: 50%;
      @include transform(translate(-50%, -50%));

      &.round {
        border-radius: 50%;
      }
    }
  }
}

.round img {
  border-radius: 50%;
}

#block-welcome-sitename {
  margin: 0 0 4px;

  .pane-content {
    font-size: 3.6rem;
    font-weight: 300;
    color: #404454;
    text-align: center;
  }
}

#block-welcome-slogan {
  margin-top: 0px;
  margin-bottom: 45px;

  .pane-content {
    font-size: 18px;
    font-weight: 400;
    text-align: center;
  }
}

//Yoti Account
#yoti-connect-register {
  background: $light;
  @include box-shadow($crm_box-shadow-global);
  margin: 50px auto 0;
  max-width: 492px;
  padding: 20px 20px 5px;
  width: 100%;

  .form-checkbox {
    margin-top: 0;
  }

  .form-submit {
    min-width: 82px;
  }
}

// Use yoti button
.yoti-connect {
  text-align: center;

  a {
    font-size: 14px !important;
  }
}
