.chr_profile-card {
  display: inline-block;
}

.chr_profile-card__name {
  display: table-cell;
  vertical-align: middle;
}

.chr_profile-card__picture {
  display: table-cell;
  overflow: hidden;
  vertical-align: middle;

  > img {
    background: $gray-light;
    border-radius: 50%;
    width: 100%;
    vertical-align: middle;
  }

  + .chr_profile-card__name {
    padding-left: 15px;
  }
}

.chr_profile-card__picture--small {

  > img {
    height: 34px;
    width: 34px;
  }
}
