/// Mixin to prefix a property
/// @author Hugo Giraudel
/// @param {String} $property - Property name
/// @param {*} $value - Property value
/// @param {List} $prefixes (()) - List of prefixes to print
@mixin prefix($property, $value, $prefixes: ()) {
  @each $prefix in $prefixes {
    #{'-' + $prefix + '-' + $property}: $value;
  }

  // Output standard non-prefixed declaration
  #{$property}: $value;
}
