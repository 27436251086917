.time-amount-picker {

  .crm_custom-select {
    display: inline-block; // Selectors need to appear in a single line
    margin-right: 2px; // A gap between a dropdown and a label

    &:nth-child(2) {
      margin-left: 10px; // A gap between dropdowns
    }
  }
}
