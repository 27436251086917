.chr_leave-report {

  .dropdown-toggle {
    color: $gray-darker;
    padding: 0 10px;

    &:hover {
      background: #e6e6e6;
    }
  }

  table {
    table-layout: fixed;

    thead th,
    tbody td {
      padding: 8px 5px;
    }
  }
}

.chr_leave-report__label {
  color: $gray-darker;
  font-weight: 700;

  > .fa {
    margin-right: 5px;
  }
}

.chr_leave-report__label--toggle {
  cursor: pointer;
  user-select: none;
}


.chr_leave-report__heading {
  background-color: $gray-lighter;
  border-bottom: 1px solid $chr-panel-border;

  .chr_leave-report__export-button {
    line-height: 2.4;
  }
}


.chr_leave-report__table {

  table,
  .table {
    @include table-first-row-border();

    // Fix for a rendering bug in firefox and chrome, where the background
    // is painted over the cell borders
    border-collapse: separate;

    > thead {

      > tr {

        // The headings have a different background and different color than normal cells
        > th {
          background: $gray-lighter;
          border-bottom: 0;
          color: $gray-darker;
          text-transform: capitalize;
        }
      }
    }

    > tbody {

      > tr:first-child > td {
        border-color: $table-border-color !important;
      }
    }

    > tfoot {

      > tr {
        background: $gray-lighter;
      }
    }
  }

  // The outer table's headings have a different padding than normal cells
  > table,
  > .table {

    > thead {

      > tr {

        > th {
          $padding-y: nth($chr-table-heading-padding, 1);

          padding-bottom: $padding-y;
          padding-top: $padding-y;
        }
      }
    }
  }
}

.chr_leave-report__table-container {
  overflow: auto;

  .panel {
    display: inline-block;
    min-width: 100%;
  }
}

.chr_leave-report__table__heading--action {
  width: 45px;
}

.chr_leave-report__table__heading--label {
  width: 280px;
}

.chr_leave-report__table__heading--dates {
  width: 170px;
}

.chr_leave-report__table__heading--status {
  width: 180px;
}

.chr_leave-report__table__heading--type {
  width: 65px;
  word-break: break-word;
}
