@at-root {
  @font-face {
    font-family: 'icomoon';
    src:
      url('../fonts/icomoon.ttf?tozcjz') format('truetype'),
      url('../fonts/icomoon.woff?tozcjz') format('woff'),
      url('../fonts/icomoon.svg?tozcjz#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
  }
}

.chr_logo {
  display: inline-block;
  font-family: 'icomoon' !important;
  font-size: 1em;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  position: relative;
  text-transform: none;
  speak: none;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &:after {
    color: inherit;
    content: "\e900";
    margin-left: 0.45em;
  }

  &:before {
    color: inherit;
    content: "\e901";
    left: 0;
    position: absolute;
    top: 0;
  }
}

.chr_logo--default-color {
  &:after {
    color: #82BD58;
  }

  &:before {
    color: #1B6F90;
  }


  &.chr_logo--full  {
    > i:after {
      color: #026085;
    }
  }
}

.chr_logo--default-size {
  font-size: 20px;
}

.chr_logo--full {
  &:after {
    left: 0;
    position: absolute;
    top: 0;
  }

  > i:after {
    bottom: .2em;
    color: inherit;
    content: "\e902";
    font-size: .7em;
    font-style: normal;
    margin-left: 2.4em;
    position: relative;
  }
}
