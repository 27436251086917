.chr_header {
  @include clearfix;

  background: $gray-dark;
  box-shadow: 0 2px 3px 0 rgba($crm-black, 0.15);
  color: $light-pale;
  font-size: 1.3rem;
  height: $crm-header-height;
  line-height: $crm-header-height;

  > .chr_header__corner {
    float: left;
  }

  > .chr_header__logo {
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    text-align: center;
    width: 25%;
  }

  > .chr_header__nav {
    height: auto;
    left: -100%;
    min-height: 100%;
    position: absolute;
    top: $crm-header-height;
    transition: left 0.2s ease-out;
    width: 100%;

    &.is-open {
      left: 0;
    }
  }

  > .chr_user-menu {
    float: right;
  }

  .menu-link-home {
    display: none;
  }

  @media(min-width: $screen-lg-min) {
    > .chr_header__logo {
      display: none;
    }

    > .chr_header__nav {
      float: left;
      height: auto;
      margin-left: 10px;
      min-height: auto;
      position: static;
      width: auto;
    }
  }

  @media (max-width: $screen-sm-max) {
    .menu-link-home {
      display: block;
    }
  }
}

.chr_header__corner {
  background: $brand-primary;
  display: table;
  height: 100%;
  text-align: center;
  width: 60px;

  &:hover {
    color: rgba($light, 0.8);
  }

  > .chr_header__logo {
    display: none;
    height: 50px;
    text-align: center;

    &:hover {
      color: rgba($light, 0.8);
    }
  }

  @media(min-width: $screen-lg-min) {
    > .chr_header__logo {
      display: block;
    }

    > .chr_header__nav__toggle {
      display: none;
    }
  }
}

.chr_header__logo {
  color: $light;
  display: inline-block;
  font-size: 1.6rem;
}

.chr_header__nav {
  background: $light;
  font-size: 1.385em;
  z-index: 99;

  @media(min-width: $screen-lg-min) {
    background: inherit;
    font-size: inherit;
    padding: 0;
  }
}

.chr_header__nav__menu {
  display: flex;

  a {
    border: 0;
    color: inherit;
    margin: 0;
    padding: 0 10px;

    &:hover,
    &:focus {
      background: $gray;
      color: $light;
    }

    &.active { /* stylelint-disable-line selector-no-qualifying-type */
      color: inherit;
    }

    // The fa classes are applied by Drupal to the anchors directly,
    // so the style on the anchor needs to be resetted and the style
    // of the actual icon needs to be "confirmed" again
    // (see: https://www.dannyenglander.com/blog/drupal-theming-adding-font-awesome-icons-menu-items)
    &.fa { /* stylelint-disable-line selector-no-qualifying-type */
      font-family: inherit;
      -webkit-font-smoothing: inherit;
      -moz-osx-font-smoothing: inherit;
      line-height: inherit;

      &::before {
        @include fa-icon();
        margin-right: 5px;
      }
    }
  }

  > li {
    position: relative;

    &.active { /* stylelint-disable-line selector-no-qualifying-type */
      &::after {
        border: 2px solid $brand-primary;
        bottom: 0;
        content: '';
        left: 0;
        margin: auto;
        position: absolute;
        right: 0;
        width: 100%;
      }
    }

    &:last-child {
      > a {
        border: 0;
      }
    }
  }

  .menu-link-hr-admin {
    line-height: 33px;
    order: 9999; // to ensure it appears the last in the list
    padding: 12px 0 0 15px;
    text-transform: uppercase;

    > a {
      border: 1px solid $light;
      color: $light;
    }
  }

  @media(max-width: $screen-md-max) {
    flex-direction: column;

    > li {
      border-bottom: 1px solid $crm-grizzly;

      /* stylelint-disable selector-no-qualifying-type */
      &.active {
        background-color: $gray-light;
      }

      &.active::after {
        content: none;
      }
      /* stylelint-enable selector-no-qualifying-type */
    }

    a {
      color: $gray-darker;
      display: block;
      margin: 0 25px;
      padding: 0 20px;

      /* stylelint-disable selector-no-qualifying-type */
      &.active,
      &:hover,
      &:focus {
        background: none;
        color: $brand-primary;
        outline: none;
      }
      /* stylelint-enable selector-no-qualifying-type */
    }

    .menu-link-hr-admin {
      border: 1px solid $gray-darker;
      line-height: 50px;
      margin: 25px auto;
      padding: 0;
      text-align: center;

      > a {
        color: $gray-darker;
      }
    }
  }

  @media(max-width: $screen-sm-max) {
    .menu-link-hr-admin {
      display: none;
    }
  }

  @media(min-width: $crm-screen-lg-max) {
    a {
      padding: 0 15px;
    }
  }
}

.chr_header__nav__toggle {
  color: $light;
  cursor: pointer;
  display: table-cell;
  height: 100%;
  line-height: normal;
  vertical-align: middle;
}


.chr_header__settings-menu {
  display: inline-block;
  float: right;
  position: relative;
  text-align: center;
  width: 50px;

  &:hover {
    background-color: $gray;

    > .chr_header__sub-menu {
      display: block;
    }
  }

  > .fa-cog {
    font-size: 16px;
  }

  .chr_header__sub-menu {
    right: 0;
  }
}

.chr_header__sub-menu {
  background: $crm-gray-darker-alt;
  display: none;
  position: absolute;
  z-index: 99;

  li {
    text-align: left;
  }

  a {
    color: $light-pale;
    display: block;
    font-size: 1.3rem;
    padding: 0 30px 0 20px;
    white-space: nowrap;

    &:hover {
      background: $gray;
      color: $light;
    }

    > i {
      margin-right: 10px;
    }
  }
}

.chr_header__sub-menu__separator {
  &::after {
    border-top: 1px solid $gray-darker;
    content: '';
    display: block;
    height: 0;
    margin: 0 30px 0 20px;
  }
}

.not-logged-in {
  .chr_header__corner {
    display: none;
  }
}
