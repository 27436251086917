.page-admin,
.page-node-add-hr-documents {
  .tabs,
  .action-links,
  .nav.nav-pills.secondary {
    margin: 20px 0;
  }

  .customSelect {
    margin: 0 0 20px;
  }

  .panel-title {
    font-size: 14px;
  }

  .table-responsive {
    margin: 0 3px 5px;
  }

  .panel-default {
    padding-top: 70px;
    position: relative;

    legend {
      background: $light;
      left: 0;
      line-height: 30px;
      position: absolute;
      top: 0;
    }
  }

  table {
    @include box-shadow($crm-box-shadow-global);
    background: $light;

    th {
      height: 45px;
    }
  }
}

.page-node-edit.node-type-hr-documents,
.page-node-add-hr-documents {
  .field-name-body .filter-wrapper {
    display: none;
  }

  .form-item-publish-status {
    margin-top: 20px;

    label {
      font-weight: 700;
    }
  }
}

.page-node-add-hr-documents {
  .form-type-select.form-item-body-und-0-format {
    width: 200px;

    label {
      display: block;
    }
  }

  #edit-submit {
    margin-left: 10px;
  }

  #edit-body-und-0-format {
    .customSelectInner {
      width: auto !important;
    }
  }
}

.page-node-edit.node-type-hr-documents {
  #edit-actions {
    input {
      margin-left: 10px;
    }
  }
}
