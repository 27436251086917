.chr_leave-request-files_list {
  height: 150px;
  overflow-y: auto;
  margin-top: 20px;
  margin-bottom: 20px;
}

.chr_leave-request-files_meta {
  margin-bottom: 10px;
}

.chr_leave-request-files_upload {
  border: dashed 1px $gray-darker;
  padding: 20px;
}

.chr_leave-request-files_upload_action {
  padding: 5px;
}

.chr_leave-request-files_file-name {
  display: inline-block;
  max-width: 240px;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: bottom;
}
