.chr_leave-request-comment{
  margin-top: 10px;
}

.chr_leave-request-comment__text {
  border-bottom: 1px solid $gray-light;
  color: $crm-color-neutral;
  margin-right: 10px;
  padding-bottom: 20px;
}
