.badge {
  &.badge-primary {
    background-color: $brand-primary;
  }

  &.badge-secondary {
    background-color: $crm-brand-secondary;
  }

  &.badge-success {
    background-color: $brand-success;
  }

  &.badge-info {
    background-color: $brand-info;
  }

  &.badge-warning {
    background-color: $brand-warning;
  }

  &.badge-danger {
    background-color: $brand-danger;
  }
}
