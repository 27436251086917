$row-margin-bottom: 15px;

.chr_leave-request-files_delete {
  margin-top: -7px;
}

.chr_leave-request-modal {
  .modal-title {
    font-size: 18px !important;
  }

  .table-responsive {
    border: 0;
  }

  .chr_leave-request-date-picker-container {
    .input-group {
      width: 100%;
    }
  }

  @media screen and (max-width: $screen-xs-max) {
    .chr_leave-request-date-picker-container {
      margin-bottom: 10px;
    }
  }
}

.chr_leave-request-modal__chevron {
  font-size: 0.6em;
  margin-right: 5px;
  vertical-align: middle;
}

.chr_leave-request-modal__comments {
  height: 230px;
  margin-bottom: 20px;
  overflow: auto;
}

.chr_leave-request-modal__comments_empty {
  margin-bottom: 20px;
}

.chr_leave-request-modal__footer {
  margin: 0;
}

.chr_leave-request-modal__delete {
  margin-left: 15px;
  margin-top: 8px;
}

.chr_leave-request-modal__form {

  .ui-select-container {
    margin-bottom: 20px;

    // TEMP: without opacity: 1 the dropdown is not visible
    ul {
      opacity: 1 !important;
    }

    &:not(.ui-select-multiple) input {
      /*the width was getting set to 503px in element itself thus causing the input box to reduce*/
      width: 100% !important;
    }
  }
}

.chr_leave-request-modal__form-group,
.chr_leave-request-modal__required-documents > div {
  margin-bottom: $row-margin-bottom;
}

.chr_leave-request-modal__response {
  padding: 20px 20px 0;

  label {
    font-size: $font-size-large;
    margin-top: -3px;
  }

  .chr_wysiwyg {
    margin-top: 15px;
  }
}

.chr_leave-request-modal__required-documents input {
  margin-right: 5px;
}

.chr_leave-request-modal__small_spinner {

  > div.chr_spinner {
    margin-top: 18px;
    min-height: initial;
  }
}

.chr_leave-request-modal__tab {
  /*
  * This is a TEMP fix to fit the text of input controls like date picker within input box
  * It is hardcoded to 13px for now.
  */
  input[uib-datepicker-popup] {
    font-size: 13px;
  }
}

.chr_leave-request-modal__table {
  border: 1px solid $gray-lighter;
  margin-bottom: $row-margin-bottom !important;
}

.chr_leave-request-modal__user-info {
  font-size: $font-size-large;
  padding: 20px;

  > span {
    &:nth-child(1) {
      color: $gray-darker;
    }

    &:nth-child(2) {
      color: $gray;
    }

    @media screen and (min-width: $screen-xs-min) {
      &:nth-child(2) {
        border-left: 1px solid $gray-light;
      }
    }
  }
}

.chr_leave-request-modal__span-expiry {
  display: inline-block;
  margin-top: 7px;
}

.chr_leave-request-modal__expiry-clear {
  position: absolute;
  right: 3.5em;
  top: 0.6em;
  z-index: 2;
}

.chr_leave-request-modal__small-text {
  font-size: 80%;
}

.chr_leave-request-modal__custom-deduction-toggling-row {
  margin-top: -5px; // The toggling row needs to look "attached" to the row above it
}

.chr_leave-request-modal__custom-deduction-label {
  @media screen and (min-width: $screen-xs-max + 1px) {
    text-align: right;
  }
  @media screen and (max-width: $screen-xs-max) {
    margin-bottom: 10px;
  }
}


/*
 * This is a temporary patch which fixes CSS in the modal
 * @TODO remove this patch when Shoreditch theme is migrated to SSP
 */
.chr_leave-request-modal {
  .form-group {
    overflow: inherit !important;
  }

  .alert {
    margin-bottom: 0 !important;
  }

  /*
   * This is needed because date picking inputs must be read-only
   * as they call calendar instead of manual input,
   * but default bootstrap theme makes them grey and not attractive to a click.
   * This is one of exceptions when read-only fields should look like regular inputs.
   */
  input[uib-datepicker-popup] {
    box-shadow: none !important;
    background-color: inherit !important;
    color: inherit !important;
    cursor: pointer !important;
    border-right: none;
  }
}
