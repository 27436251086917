// Styles for Forms
// --------------------------------------------------

// Styles for astrisk (required indicator)
.required-field-indicator {
  &::after {
    color: $brand-danger;
    content: '*';
    font-size: 13px;
    padding-left: 5px;
    position: absolute;
  }
}

#civihr-employee-portal-request-new-account-form {
  label {
    display: none;
  }
}



/**
 * Staff Directory filters, HR Resources filters, Users List
 */
#views-exposed-form-users-list-page,
#views-exposed-form-civihr-staff-directory-page,
#views-exposed-form-hr-documents-hr-resources {
  .form-item {
    margin-bottom: 20px;
  }

  .views-exposed-widgets {
    margin-bottom: 0;
  }

  .views-submit-button {
    clear: both;

    .form-submit {
      margin-top: 0;
    }
  }
}

#views-exposed-form-users-list-page {
  #edit-submit-users-list {
    margin-right: 15px;
  }
}

#views-form-users-list-page {
  @media(max-width: $screen-sm-min) {
    .panel-body .form-submit {
      margin-top: 20px;
    }
  }
}

/**
 * # TEMPORARY #
 *
 * This is a reset of all the style applied by default by .container-inline-date
 */
.civihr_container-inline-date--reset {
  > .container-inline-date {
    clear: initial;

    .date-padding {
      float: initial;
      padding: 0;
    }

    .form-control {
      margin-right: 0;
      width: 100%;
    }

    .form-group.form-item {
      float: initial;
      margin: 0;
    }

    > .form-group.form-item {
      display: block;
      float: initial;
      margin: 0 -15px 15px;
      vertical-align: initial;
      width: initial;
    }
  }
}



/**
 * Custom style for a <form>
 */
.civihr_form {

  // Boostrap override
  &.form-horizontal {
    .control-label {
      font-weight: normal;
      text-align: left;
    }
  }

  .civihr_form__fieldset {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  .form-actions {
    > .form-submit {
      padding: 0.8rem 6rem;
    }
  }

  .form-file {
    background: transparent;
    display: inline;
    padding: 7px 0;
  }

  // Adds calendar icon, remove additional labels and help tips
  // and overrides bootstrap styles for internal elements
  .form-type-date-popup {
    .date-padding {
      position: relative;

      &::before {
        content: '\f073';
        font-family: FontAwesome;
        position: absolute;
        right: -20px;
        top: 7px;
      }

      /* stylelint-disable selector-max-compound-selectors */
      label,
      .help-block {
        display: none !important;
      }
      /* stylelint-enable selector-max-compound-selectors */
    }

    .form-control {
      margin-right: 0;
      width: 100%;
    }

    .form-group {
      margin: 0;
    }
  }

  /* stylelint-disable selector-max-compound-selectors */
  // Custom style for select2
  .select2-container.form-text {
    border: 0;
    padding: 0;
    width: 100% !important;

    > .select2-choice {
      height: auto;
      line-height: inherit;
      padding: 7px 10px;

      abbr {
        top: 11px;
      }
    }

    &.select2-dropdown-open {
      .select2-arrow b {
        background-position: -18px 5px;
      }
    }

    &:not(.select2-dropdown-open) {
      .select2-arrow b {
        background-position: 0 5px;
      }
    }
  }
  /* stylelint-enable selector-max-compound-selectors */

  // Place the 3 select dropdowns (DD/MM/YYYY) in columns
  //
  // This is mostly a duplicate of .form-group--smaller-gutter
  // given I couldn't change the markup of the datepicker field itself
  .webform-datepicker {
    @extend .row; /* stylelint-disable-line scss/at-extend-no-missing-placeholder */

    margin-left: -10px;
    margin-right: -10px;

    .form-item {
      @extend .col-sm-4; /* stylelint-disable-line scss/at-extend-no-missing-placeholder */

      display: block;
      margin-bottom: 10px;
      padding-left: 10px;
      padding-right: 10px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .webform-calendar {
      display: none;
    }
  }

  // Fixes a IE9 bug with <span> containing block elements
  // that resulted in Bootstrap cols being misaligned
  .ie9 & {
    .field-prefix {
      display: none;
    }
  }

  @media(min-width: $screen-sm-min) {
    .webform-datepicker {
      .form-item {
        margin-bottom: 0;
      }
    }
  }
}

// Custom style for a <fieldset>,
// (based on the markup printed out by the Form API)
.civihr_form__fieldset {
  margin: 0;
  padding: 0;

  .fieldset-legend {
    font-size: 1.6rem;
  }

  .fieldset-title {
    margin-left: 10px;
  }
}

// [modifier] Transparent <fieldset> (no borders, paddings, bg colors)
.civihr_form__fieldset--transparent {
  border: 0;
  box-shadow: none;

  &.collapsed {
    > legend {
      margin-bottom: 0;
    }

    .fieldset-legend {
      @include fa('\f054', 10);
    }
  }

  > legend {
    background: none !important;
    border: 0;
    padding: 0;
  }

  .fieldset-legend {
    @include fa('\f078', 10);

    background: none !important;
    font-size: 1.6rem;
    padding-left: 0 !important;

    &::before {
      width: 10px;
    }

    > .fieldset-title {
      margin-left: 5px;
      vertical-align: middle;
    }
  }

  .panel-body {
    padding: 0;
  }
}

/* [modifier] Resets any margin/padding applied by default */
.civihr_form--reset {
  .form-actions,
  .form-item {
    margin: 0;
  }
}
