.chr_wysiwyg {
  border-bottom: none !important;

  /*
   * If opacity is 0.65 as per default, then the whole text editor
   * looks unavailable to be used. A decision was taken to set the opacity
   * of the toolbar buttons to 1 even if they are disabled.
   */
  [text-angular-toolbar] {
    .btn[disabled] {
      opacity: 1;
    }
  }

  .placeholder-text {
    opacity: 0.6;
  }
}

.chr_wysiwyg__action {
  background-color: $crm-white;
  border: 1px solid $gray-light;
  border-top: none;
  padding: 5px 0;
  text-align: right;

  hr {
    margin: 0 10px;
  }

  .btn-link {
    margin-top: 5px;

    &[disabled] {
      opacity: 0.6;
    }
  }

  .fa {
    margin-right: 5px;
  }
}
