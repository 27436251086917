.crm_wizard {
  &__body,
  &__title {
    margin-bottom: 30px;
  }

  &__title {
    .crm_wizard__title__number {
      border: 2px solid;
      border-radius: 50px;
      display: inline-block;
      height: 32px;
      line-height: 28px;
      margin-right: 5px;
      text-align: center;
      width: 32px;
    }

    .nav-pills {
      padding: 0;

      a,
      a:active,
      a:hover {
        color: $crm-wizard-active-font-color;
        padding: 15px 20px;
      }

      li.active a {
        background: $crm-wizard-active-bg-color;
        color: $crm-wizard-active-font-color;
        position: relative;

        &:after,
        &:before {
          border-color: transparent;
          border-style: solid;
          border-width: 30px 0 30px 8px;
          bottom: 0;
          content: '';
          height: 100%;
          position: absolute;
          top: 0;
          width: 0;
        }

        &:after {
          border-left-color: $crm-wizard-active-bg-color;
          right: -7px;
        }

        &:before {
          border-left-color: $crm-white;
          left: 0;
        }
      }

      li.active:first-child a:before {
        display: none;
      }

      li:not(.active) a {
        color: $gray-darker;

        &:after,
        &:before {
          background: $crm-wizard-active-bg-color;
          content: '';
          height: 31px;
          position: absolute;
          right: -9px;
          width: 1px;
        }

        &:after {
          @include prefix(transform, skewX(-15deg), webkit ms moz);
          bottom: 0;
        }

        &:before {
          @include prefix(transform, skewX(15deg), webkit ms moz);
          top: 0;
        }

        &:hover {
          background: none;
          color: $crm-wizard-active-font-color;
        }
      }

      li.completed a {
        color: #00B0B9;
      }
    }

    .panel-body {
      padding: 0;
    }
  }

  &__body {
    .form-group,
    .form-group-lg {
      max-width: 550px;
    }

    .form-group-lg .form-control {
      min-width: 100%;
      margin-bottom: 15px;
    }

    .form-control,
    .form-group .select2-container {
      max-width: 370px;
    }

    .select2-container {
      height: auto;
    }
  }

  .panel-body {
    border-radius: $border-radius-small;
  }
}
