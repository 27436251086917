.drop-zone-overlay {
  align-items: center;
  background: #ecedee;
  color: $crm-wizard-font-color;
  display: flex;
  justify-content: center;
  height: 180px;
  min-width: 120px;
  outline: 1px dashed #7a8084;
  pointer-events: none;
  position: absolute;
  text-align: center;
  transition: outline-offset .15s ease-in-out, background-color .15s linear;
  top: 0;
  width: 100%;

  i {
    color: $crm-wizard-secondary-color;
    font-size: 25px;
    margin-bottom: 15px;
  }

  &.is-dragover {
    outline-offset: -10px;
    outline-color: #c8dadf;
    background-color: $light;
  }
}

.mobile {
  .drop-zone-overlay {
    background: $brand-primary !important;
    color: $light;
    height: 40px !important;
    outline: none;
  }
}
