$chr-user-menu-dropdown-item-gap: 0 30px 0 20px;

.chr_user-menu {
  color: $chr-user-menu-color;
  cursor: default;
  line-height: $chr-user-menu-line-height;
  padding: 0 10px;
  position: relative;

  &:hover {
    background: $chr-user-menu-bg-hover;

    > .chr_user-menu__dropdown {
      display: block;
    }
  }
}

.chr_user-menu__data {

  > .chr_profile-card {
    margin: -2px 8px 0 8px; // To improve, the vertical margin is just to vertically align the card
    vertical-align: top;
  }

  @media(max-width: $chr-user-menu-minify-breakpoint) {
    > .chr_user-menu__arrow {
      display: none;
    }
  }
}

.chr_user-menu__dropdown {
  background: $chr-user-menu-dropdown-bg;
  display: none;
  position: absolute;
  right: 0;
  z-index: 99;

  ul {
    list-style-type: none;
    margin: 0;
  }

  li {
    line-height: 50px;
    white-space: nowrap;

    > a,
    > span {
      padding: $chr-user-menu-dropdown-item-gap;
    }
  }

  a {
    color: $chr-user-menu-dropdown-link-color;
    display: block;

    &:hover {
      background: $chr-user-menu-dropdown-link-bg-hover;
      color: $chr-user-menu-dropdown-link-color-hover;
      text-decoration: none;
    }

    > i {
      margin-right: 10px;
    }
  }
}

.chr_user-menu__dropdown__username {
  color: $chr-user-menu-dropdown-username-color;

  &:after {
    border-top: 1px solid $crm-grayblue-dark;
    content: '';
    display: block;
    height: 0;
    margin: $chr-user-menu-dropdown-item-gap;
  }
}
