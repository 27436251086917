// Styles for panels and panes.
// --------------------------------------------------

.pane-block.pane-user-login {
  margin-bottom: 20px;
  padding-bottom: 0;

  label {
    display: none;
  }

  #messages {
    padding: 0;
    margin-bottom: 20px;

    .alert {
      padding: {
        bottom: 15px;
        top: 15px;
      };
    }
  }

  .btn {
    min-width: 82px;
  }

  .pane-content {
    @include grid-condensed($panel-body-padding);

    > .form-item:first-child {
      padding-top: 0;
    }
  }

  .pane-user-login-forgot-password {
    > .row {
      background-color: #f0f1f4;
    }

    .alert {
      margin-bottom: -20px;
    }
  }
}
