$carousel-box-shadow-color: rgba(#3f3c55, 0.3);

.page-features-in-civihr {
  .onboarding_wizard_features_get_started {
    background: $crm-wizard-secondary-color;
    display: block;
    margin: 40px auto 0;
    text-transform: uppercase;
    width: 200px;
  }

  .page-header {
    font-weight: $crm-font-weight-bold;
    margin-top: 80px;
    text-align: center;

    .title {
      font-weight: $crm-font-weight-semibold;
    }
  }

  .view-id-onboarding_slideshow {
    background: transparent;
  }

  .views-slideshow-cycle-main-frame {
    height: 303px;
    margin: 0 30px;
  }

  .views-slideshow-cycle-main-frame-row {
    width: 100% !important;

    img {
      height: 270px !important;
      width: 458px !important;
    }
  }

  .views-slideshow-cycle-main-frame-row-item {
    margin: 0 65px;
    padding: 12px;
    position: relative;

    .views-field-field-slideshow-image {
      box-shadow: 0 10px 20px 0 $carousel-box-shadow-color, 0 -2px 15px 0 $carousel-box-shadow-color;
      width: 458px;
    }

    .views-field-title {
      color: $crm-wizard-font-color;
      font-size: 26px;
      left: 458px;
      position: absolute;
      text-align: center;
      top: 100px;
      width: calc(100% - 458px);
    }

    .views-field-field-description {
      color: $crm-wizard-primary-color;
      left: 458px;
      padding: 0 40px;
      position: absolute;
      text-align: center;
      top: 150px;
      width: calc(100% - 458px);
    }
  }

  .views-slideshow-pager-fields {
    margin-top: 40px;
    text-align: center;

    .views-content-title {
      background: $light-pale;
      border-radius: 50%;
      cursor: pointer;
      display: inline-block;
      height: 10px;
      width: 10px;
    }

    .active {
      .views-content-title { /* stylelint-disable-line selector-max-compound-selectors */
        background: $crm-wizard-active-font-color;
      }
    }
  }

  .views-slideshow-cycle-processed {
    position: relative;
  }

  .views-slideshow-controls-text-pause {
    display: none;
  }

  .views-slideshow-controls-text-previous,
  .views-slideshow-controls-text-next {
    font-size: 35px;
    position: absolute;
    top: 140px;

    a {
      color: #c2cfd7;
    }
  }

  .views-slideshow-controls-text-next {
    right: 0;
  }

  @media (max-width: $screen-lg-min) {
    .views-slideshow-controls-text-previous,
    .views-slideshow-controls-text-next {
      display: none;
    }

    .views-slideshow-cycle-main-frame {
      height: 500px;
      margin: 0;
    }

    .views-slideshow-cycle-main-frame-row-item {
      margin: 0;

      .views-field-title {
        left: 0;
        top: 390px;
        width: 100%;
      }

      .views-field-field-description {
        left: 0;
        top: 450px;
        width: 100%;
      }
    }

    .views-field-field-slideshow-image {
      width: 100% !important;

      img {
        height: 350px !important;
        width: 100% !important;
      }
    }
  }

  @media (max-width: $screen-xs-min) {
    .views-slideshow-cycle-main-frame {
      height: 400px;
    }

    .views-slideshow-cycle-main-frame-row-item {
      .views-field-title {
        top: 240px;
      }

      .views-field-field-description {
        top: 290px;
      }
    }

    .views-field-field-slideshow-image {
      img {
        height: 200px !important;
      }
    }
  }
}
