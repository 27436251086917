//** Custom select
$crm-customselect-caret-width:            32;
$crm-customselect-padding-right:          $crm-customselect-caret-width + 12;
$crm-customselect-feedback-padding-right: $crm-customselect-padding-right + 10;

.crm_custom-select {
  background: $crm-white;
  display: inline-block;
  position: relative;

  > select {
    background: transparent;
    border: 1px solid $input-border;
    height: $input-height-base;
    padding: 4px #{$crm-customselect-padding-right}px 4px 12px;
    position: relative;
    width: 100%;
    z-index: 2;

    -moz-appearance: none;
    -webkit-appearance: none;
    -webkit-border-radius: 0px;

    &::-ms-expand {
      display: none;
    }

    &:disabled {

      + .crm_custom-select__arrow {
        z-index: 2;
      }
    }

    &:focus {

      + .crm_custom-select__arrow {
        border-color: $input-border-focus;
      }
    }
  }

  .ie9 & {

    > select {
      padding-right: 10px;
    }

    .crm_custom-select__arrow {
      display: none;
    }
  }
}

.crm_custom-select--full {
  display: block;
  width: auto;
}

.crm_custom-select--transparent {
  background: transparent;

  option {
    background: $crm-white;
  }
}

.crm_custom-select__arrow {
  border-left: 1px solid $input-border;
  bottom: 0;
  display: inline-block;
  line-height: #{$crm-customselect-caret-width - 1}px;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: #{$crm-customselect-caret-width}px;
  z-index: 1;

  &:before {
    content: '\f0d7';
    color: $text-color;
    font-family: "FontAwesome";
    font-style: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
  }
}

.has-error {

  .crm_custom-select__arrow {
    border-color: $brand-danger !important;
  }
}

.has-feedback {

  > .crm_custom-select {

    > select {
      padding-right: #{$crm-customselect-feedback-padding-right}px;
    }

    + .form-control-feedback {
      right: #{$crm-customselect-caret-width - 5}px !important;
    }
  }
}
