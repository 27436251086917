// Custom scrollbar
// --------------------------------------------------
.chr_custom-scrollbar {
  position: relative;
  overflow: auto;
  padding-bottom: 8px;

  .ps-scrollbar-x-rail {
    bottom: 0 !important;
    opacity: 1 !important;
    background-color: $crm-scrollbar-track-color !important;
    height: 10px !important;
  }

  .ps-scrollbar-x {
    background-color: $crm-scrollbar-thumb-color !important;
    height: 10px !important;
  }

  &::-webkit-scrollbar {
    height: $crm-scrollbar-size;
  }

  &::-webkit-scrollbar-track {
    background-color: $crm-scrollbar-track-color;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $crm-scrollbar-thumb-color;
  }
}
