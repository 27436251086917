.mobile {
  .onboarding-wizard-page {
    .onboarding_wizard_profile_pic_upload_image {
      input.form-file {
        height: 40px !important;
      }
    }
  }
}

.onboarding-wizard-page {
  // Override Browser default validation style specific to firefox
  // Ref: https://developer.mozilla.org/en-US/docs/Web/CSS/:-moz-ui-invalid
  // which adds red border shadow in required field
  input:invalid {
    box-shadow: none;
  }

  .chr_onboarding-wizard_remind-me-later {
    border: none;
    float: none;
    margin-top: 15px;

    &:hover {
      background-color: $gray;
      color: $crm-white !important;
    }
  }

  .crm_wizard__mobile {
    display: none;
  }

  .crm_wizard__title {
    .nav-pills {
      text-align: center;

      li {
        display: inline-block;
        float: none;

        a {
          color: $gray-darker !important;
          padding: 15px 5px 15px 15px;
        }

        &.active {
          a {
            color: $crm-wizard-secondary-color !important;
          }
        }

        &.completed,
        &.active {
          a {
            .crm_wizard__title__number {
              color: $crm-wizard-secondary-color;
            }
          }
        }
      }
    }
  }

  .form-group {
    display: inline-block;
    width: 100%;

    > label {
      color: $crm-wizard-font-color;
      display: block;
      font-size: 13px;
      padding: 0;
      width: 100%;
    }

    > div {
      padding: 0;
      width: 100%;

      div.form-item {
        background-color: $light;
        margin: 0;
      }
    }
  }

  .form-radios {
    background-color: transparent !important;
    display: table;
    table-layout: fixed;
    width: 100%;

    .form-type-radio {
      display: table-cell;

      label {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    > div {
      background-color: transparent !important;
      padding: 8px 12px;
      border: 1px solid $crm_color-neutral;
      border-right: none;
      margin: 0 !important;
    }

    > div:last-child {
      border-right: 1px solid $crm_color-neutral;
    }

    > div.checked {
      background-color: $crm-wizard-secondary-color !important;
      color: $light;
      border-color: transparent;
    }

    input[type='radio']:checked:after {
      border: 4px solid $light;
      border-radius: 15px;
      background-color: #000;
      content: '';
      display: inline-block;
      height: 15px;
      position: relative;
      visibility: visible;
      width: 15px;
    }
  }

  .fieldset-legend {
    &:before {
      display: none !important;
    }
  }

  .modal-civihr-custom__section,
  .onboarding-wizard-container {
    margin: -25px auto 25px auto;
    width: 475px;
    padding: 90px 0 0 0;
  }

  .onboarding-wizard-container {
    .main-container {
      margin-top: 65px;
    }

    .form-type-password {
      margin: 0;
    }

    .form-item {
      input {
        width: 100%;
      }
    }

    .password-strength {
      margin-bottom: 15px;
    }

    .password-suggestions {
      width: 100%;
    }
  }

  .modal-civihr-custom__footer {
    margin-left: auto;
    margin-right: auto;
    width: 475px;
  }

  .onboarding_wizard_payroll_gender .webform-component-radios {
    .description {
      margin-bottom: 10px;
    }
  }

  .onboarding_wizard_payroll_ssin_checkbox {
    label {
      padding-left: 20px !important;
    }

    .webform-component-checkboxes {
      background-color: transparent !important;
      margin-bottom: -18px !important;
      position: relative;
      top: -23px;
      z-index: 2;

      .form-type-checkbox {
        background-color: transparent !important;
      }
    }
  }

  .onboarding_wizard_profile_pic_upload_image {
    label {
      display: none;
    }

    input.form-file {
      height: 180px;
      margin-bottom: 15px;
      min-width: 120px;
      width: 100%;
    }

    .form-item {
      background-color: transparent !important;
    }

    .civicrm-file-icon~.drop-zone-overlay {
      display: none;
    }

    .civicrm-remove-file {
      background: $light;
      border: 1px solid #ccc;
      padding: .7rem 1.1rem;
    }
  }

  .page-header {
    display: none;
  }

  .webform-next,
  .webform-submit,
  #edit-submit {
    background: $crm-wizard-secondary-color;
    border: 0;
    color: $light;

    &:focus {
      background: $crm-wizard-secondary-color;
      color: $light;
    }
  }

  #edit-submit {
    text-transform: uppercase;
    width: 100px;
  }

  .webform-calendar,
  .mobile-webform-calendar {
    padding: 7px 10px;
    -webkit-appearance: none;

    &+.input-group-addon {
      background-color: $crm_gray-light-alt;
      border: 1px solid $crm-color-neutral;
      color: $crm-wizard-font-color;
      display: inline-block;
      height: 100%;
      line-height: 20px;
      pointer-events: none;
      position: absolute;
      right: 0;
      text-align: center;
      top: 0;
      width: 40px;
    }
  }

  .webform-component-fieldset {
    box-shadow: none;

    .fieldset-wrapper {
      background: transparent;
    }
  }

  .webform-component-markup {
    background: transparent !important;
  }

  .onboarding-fieldset-title,
  #onboarding-welcome-message {
    color: $crm-wizard-font-color;
    margin-top: -15px;
    font-size: 36px;
    font-weight: $headings-font-weight;
    text-align: center;
  }

  .webform-component-radios {
    background-color: transparent !important;
  }

  .webform-container-inline.webform-datepicker div.form-item.form-type-select {
    display: none;
  }

  .webform-previous,
  #edit-cancel {
    background: $crm-wizard-primary-color;
    border: 0;
    color: $light;
    float: left;

    &:focus {
      background: $crm-wizard-primary-color;
      color: $light;
    }
  }

  .webform-component-file {
    background-color: transparent;
  }

  .form-managed-file {
    .description {
      margin-top: 15px;
    }

    .form-submit {
      margin-top: 15px;
    }

    input[type='submit'] {
      float: right;
    }
  }

  .onboarding-company-logo {
    display: block;
    margin: 77px auto;
    width: 140px;
  }

  #messages {
    padding: 0;
  }

  @media (max-width: $crm-onboarding-wizard-menu-responsive-breakpoint) {
    .modal-civihr-custom__section,
    .modal-civihr-custom__footer,
    .onboarding-wizard-container {
      padding-left: 30px;
      padding-right: 30px;
      width: 100%;
    }

    .crm_wizard__title {
      .nav-pills {
        display: none;
      }

      .crm_wizard__mobile {
        display: block;
        padding-top: 15px;
        text-align: center;

        .crm_wizard__progress {
          background-color: $crm-wizard-active-font-color;
          height: 2px;
          margin-top: 15px;
        }

        .crm_wizard__progress-title {
          color: $crm-wizard-active-font-color;
          font-weight: 700;
        }

        .crm_wizard__current-step {
          color: $crm-wizard-font-color;
          font-weight: 300;
        }

        .crm_wizard__total-step {
          color: $crm-color-neutral;
          font-weight: 300;
        }
      }
    }
  }
}
