// In mobile the native html date picker is displayed which is a [type='date'] element.
.mobile {
  [type='date'][uib-datepicker-popup] {
    line-height: normal;

    &::-webkit-inner-spin-button,
    &::-webkit-clear-button {
      @include prefix(appearance, none, webkit);
      display: none;
    }

    &::-webkit-calendar-picker-indicator {
      background: transparent;
      bottom: 0;
      color: transparent;
      height: auto;
      left: 0;
      position: absolute;
      right: -50px;
      top: 0;
      width: auto;
    }
    /* stylelint-disable selector-max-compound-selectors */
    + .input-group-addon {
      border-left: 1px solid $crm-color-neutral !important;
      height: $input-height-base !important;
      line-height: $input-height-base !important;
      padding: 0 !important;
      pointer-events: none;
      position: absolute !important;
      right: 0 !important;
      width: 38px !important;
      z-index: 3;
    }
    /* stylelint-enable */
  }
}
