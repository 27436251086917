.chr_vertical_tabs.nav {
  background-color: $crm-grayish-blue;

  > li {

    a {
      background-color: $panel-default-heading-bg;
      color: $gray;
    }

    &.active a {
      background-color: $brand-primary;
      color: $light;
    }
  }
}
