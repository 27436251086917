//
// CSS Helpers
// --------------------------------------------------

.flex {
  @include flex;
}

.flex-md {
  @media (min-width: $screen-md-min) {
    @include flex;
  }
}

.flex-lg {
  @media (min-width: $screen-lg-min) {
    @include flex;
  }
}

.pointer {
  cursor: pointer !important;
}

.chr_divider {
  margin: 0 5px;

  @media(max-width: $screen-sm-min) {
    display: block;
    visibility: hidden;
  }
}
