// Styles for main layout
// --------------------------------------------------

/**
 * The outer wrapper of the entire site
 *
 * It is necessary because .chr_header__nav on mobile must attach to it and be as tall as it is
 * when position: absolute is applied
 */
#outer-wrapper {
  position: relative;
}
