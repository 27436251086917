// Styles for Views
// --------------------------------------------------

/**
 * [modifier] A `.view` containing a <table>
 *
 * The <table> on small screens will not adapt to the width anymore, but it will be scrollable on the X axys
 */
.view--w-table {
  overflow: auto;

  table {
    min-width: 720px;
  }

  @media(min-width: $screen-sm-min) {
    overflow: visible;
  }

  @media(min-width: $screen-md-min) {
    overflow: auto;
  }

  @media(min-width: $screen-lg-min) {
    overflow: visible;

    table {
      min-width: inherit;
    }
  }
}



/**
 * Staff Directory view, HR Resources view
 */
.view-id-civihr_staff_directory,
.view-manage-hr-resources,
.view-full-hr-documents,
.view-users-list {
  background: none;
  padding: 0;

  > .view-content {
    @include clearfix;

    background: $crm-white;
  }

  > .view-filters {
    background: $crm-white;
    margin-bottom: 20px;
    padding: $panel-body-padding;
  }
}

.view-users-list {
  .panel-heading {
    display: none;
  }

  > .view-filters {
    padding: 20px 5px;
  }
}

/**
 * HR Resources view
 */
.view-full-hr-documents {
  .views-row {
    &:not(:last-child) {
      border-bottom: 1px solid $gray-light;
    }
  }

  .views-row-odd {
    background: $gray-lighter;
  }
}
