@mixin fa-icon($font-size: null, $content: null, $color: null) {
  font-family: 'FontAwesome';
  font-style: normal;
  text-rendering: auto;
  @if($font-size) {
    font-size: $font-size;
  } @else {
    font-size: .8em;
  }
  @if($content) {
    content: $content;
  }
  @if($color) {
    color: $color;
  } @else {
    color: $gray-dark;
  }
}
