.table {
  font-size: 1.3rem;

  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        border-top: 0;
        border-bottom: 1px solid $gray-light;
      }
    }
  }

  thead {
    > tr{
      > th {
        color: $gray-darker;
        padding: 8px 20px;
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 8px 20px;
      }
    }
  }
}

// Necessary to override styles from system.theme.css
tr {
  &.even,
  &.odd {
    background-color: initial;
  }
}

// Necessary to remove the bottom margin if a table is inside a panel
.panel {

  .table,
  .table-responsive {
    margin-bottom: 0;
  }
}
